/* eslint-disable react/no-unescaped-entities */

import React from 'react'
import { Email } from '../../components/shared/email'
import LegalLayout from '../../components/shared/legal-layout'
import Link from '../../components/shared/link'
import {
    DEVELOPER_EN,
    HELP_EMAIL,
    LAST_UPDATED,
    NAME_EN,
} from '../../constants'
import useDomain from '../../hooks/use-domain'

const TermsPage = () => {
    const domain = useDomain()
    return (
        <LegalLayout>
            <article>
                <h1>Terms and Conditions</h1>
                <p className="italic text-gray-500 mb-3">
                    Last updated: {LAST_UPDATED}
                </p>
                Before using the app, please read the following Terms and
                Conditions agreement (&quot;Agreement&quot;).
                <ol className="mt-5">
                    <li>
                        <h2>General Terms</h2>
                        <ol>
                            <li>
                                This Agreement sets forth the terms and
                                conditions governing the use of "{NAME_EN}"
                                mobile app ("Service") concluded between any
                                person using the Service (the "User" or "You")
                                and {DEVELOPER_EN}, who is a holder of exclusive
                                rights to the Service ("Copyright Holder").
                            </li>
                            <li>
                                By creating an account in the Service, the User
                                agrees to be bound by this{' '}
                                <Link to="/legal/policy" title=" Agreement" />{' '}
                                and the{' '}
                                <Link
                                    to="/legal/policy"
                                    title=" Privacy Policy"
                                />
                                , which is incorporated by reference into this
                                Agreement.
                            </li>
                            <li>
                                Use of the Service is permitted only under the
                                terms of this Agreement. If the User does not
                                accept the terms of the Agreement in full, the
                                User has no right to use the Service for any
                                purpose. Any use of the Service in violation of
                                any of the terms of the Agreement is prohibited.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Eligibility</h2>
                        <ol>
                            <li>
                                You must be at least 18 years of age to use the
                                Service (or older, if your country of residence
                                has a higher age of majority). By creating an
                                account and using the Service, You represent and
                                warrant that:
                                <ul>
                                    <li>
                                        You confirm that you have the right and
                                        are able to bind yourself to comply with
                                        the Terms and Conditions, and will not
                                        violate the laws and regulations of your
                                        country by using the Service. You are
                                        solely responsible for complying with
                                        all existing local laws and regulations.
                                    </li>
                                    <li>
                                        You will comply with this Agreement and
                                        all applicable local, state, national
                                        and international laws, rules and
                                        regulations;
                                    </li>
                                    <li>
                                        You have never been convicted of
                                        socially dangerous assault, physical
                                        harm, or sexual harassment.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>User Account</h2>
                        <ol>
                            <li>
                                When communicating with our customer service
                                representatives, you agree to be respectful and
                                kind. If we feel that your behavior towards any
                                of our customer service representatives or other
                                employees is at any time threatening or
                                offensive, we reserve the right to immediately
                                terminate your account.
                            </li>
                            <li>
                                In consideration for the Copyright Holder
                                allowing you to use the Service, you agree that
                                the Copyright Holder, their affiliates, and
                                third-party partners may place advertising on
                                the Service. By submitting suggestions or
                                feedback to the Copyright Holder regarding the
                                Services, you agree that the Copyright Holder
                                may use and share such feedback for any purpose
                                without compensating you.
                            </li>
                            <li>
                                You agree that the Copyright Holder may access,
                                store and disclose your account information and
                                Content if required to do so by law, or in a
                                good faith belief that such access, storage or
                                disclosure is reasonable, for instance: to
                                comply with legal process; to enforce the
                                Agreement; to respond to claims that any Content
                                violates the rights of third parties; to respond
                                to your requests for customer service; to
                                protect the rights, property or personal safety
                                of the Company or any other person.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Community Rules</h2>
                        By using the Service, You agree that You will not:
                        <ul>
                            <li>
                                use the Service for any purpose that is illegal
                                or prohibited by this Agreement
                            </li>
                            <li>
                                use the Service for any harmful or nefarious
                                purpose
                            </li>
                            <li>
                                use the Service in order to harm the Copyright
                                Holder
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h2>Purchases</h2>
                        You can make purchases via iTunes or Google Play
                        (&quot;Store&quot;) within the Service. In order to make
                        a purchase you will be asked to enter your account
                        information in the Store. After that, your Store account
                        will be charged for services purchased through the app,
                        in accordance with the terms in effect at the time of
                        purchase, as well as in accordance with the general
                        terms of purchases made through the app that apply to
                        your Store account. Some Stores may charge you with
                        sales tax depending on your country of residence. If you
                        purchase an auto-recurring periodic subscription through
                        the Service, your Store account will continue to be
                        billed for the subscription until you cancel it. After
                        your initial subscription commitment period, and again
                        after any subsequent subscription period, your
                        subscription will automatically continue for an
                        additional equivalent period, at the price you agreed to
                        when subscribing. If you want to cancel auto-renewal of
                        subscription, change or terminate your subscription, you
                        will need to log in to your Store account and follow
                        instructions to cancel your subscription, even if you
                        have otherwise deleted your account or if you have
                        deleted the Service from your device. Deleting your
                        account on the Service or deleting the Service from your
                        device does not terminate or cancel your subscription;
                        the Service will continue to charge funds from the Store
                        account until You cancel your subscription through Store
                        account.
                    </li>
                    <li>
                        <h2>Liability by Agreement</h2>
                        <ol>
                            <li>
                                The Copyright Holder is not responsible for any
                                direct or indirect consequences of any use or
                                inability to use the Service and/or damage
                                caused to the User and/or third parties as a
                                result of any use, non-use or inability to use
                                the Service, including possible errors or
                                failures in the operation of software and
                                hardware, except for cases expressly provided by
                                law.
                            </li>
                            <li>
                                All questions and complaints related to the
                                use/inability to use the Service, as well as
                                possible violations of the law by the Service
                                and/or the rights of third parties, should be
                                sent via the feedback form at the following
                                email address:{'  '}
                                <Email email={HELP_EMAIL} />.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Final Provisions</h2>
                        <ol>
                            <li>
                                The agreement (including any of its parts) may
                                be changed by the Copyright Holder unilaterally
                                without any special notice, the latest version
                                of the document shall come into effect upon
                                posting on the Internet at the address specified
                                in this paragraph unless otherwise provided by
                                the new version of the Terms and Conditions. The
                                most recent version of Terms and Conditions is
                                always available at:{'  '}
                                <Link
                                    to="/legal/terms"
                                    title={`${domain}/legal/terms`}
                                />
                                . If the Copyright Holder has made any changes
                                in this Agreement, in the manner described in
                                this paragraph, with which the User does not
                                agree, they must stop using the Service.
                            </li>
                            <li>
                                The legislation of the Republic of Belarus
                                applies to this Agreement and all relations
                                related to the use of the Service, Program and
                                Data, any claims arising from this Agreement or
                                the use of the Service must be filed and
                                considered in court at the location of the
                                Copyright Holder.
                            </li>
                        </ol>
                    </li>
                </ol>
            </article>
        </LegalLayout>
    )
}

export default TermsPage
